<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields"
      @submit="headleQueryFrom"
      @reset="handleQueryFrom"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/ PDA管理/[新建]'
          }"
          @click="headleAddPDA"
        >
          新建
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/ PDA管理/[导入]'
          }"
        >
          导入
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/ PDA管理/[导出]'
          }"
        >
          导出
        </nh-button>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="headleEditPDA(row)">
          编辑
        </nh-button>
      </template>
      <template #pdaBrand="{row}">
        <span>{{ filterLabel(row.pdaBrand, PDA_BRAND) }}</span>
      </template>
      <template #pdaBrandType="{row}">
        <span>{{ filterLabel(row.pdaBrandType, $dictionaryFiledMap[row.pdaBrand]) }}</span>
      </template>
    </PlTable>
  </pl-block>
  <edit-dialog ref="childEdit" @headleSuccess="getTableData" />
</template>
<script>
import loadingMixin from '@thales/loading';
import EditDialog from './components/editDialog.vue';
import mixin from './mixin';
import { TABLECOLUMN, serchFields } from './fileds';
import { getPdaPage } from './api';

const moment = require('moment');

export default {
  name: 'StorageEquipment',
  components: {
    EditDialog,
  },
  mixins: [loadingMixin, mixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      tableData: [],
      formData: {},
      loading: {
        getTableData: false,
      },
    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    filterLabel(val, arr) {
      const findArr = arr && arr.find((item) => item.value === val);
      return findArr ? findArr.label : '';
    },
    headleQueryFrom() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await getPdaPage(pagination, this.formData);
      this.tableData = resp.records.map((item) => ({
        ...item,
        createTime: item.createTime ? moment(item.createTime).format('YYYY/MM/DD HH:mm:SS') : '',
        lastLoginTime: item.lastLoginTime
          ? moment(item.lastLoginTime).format('YYYY/MM/DD HH:mm:SS')
          : '',
      }));
      this.pagination.total = resp.total;
    },
    headleAddPDA() {
      this.$refs.childEdit.init();
    },
    headleEditPDA(row) {
      this.$refs.childEdit.init(row);
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
