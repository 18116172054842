<template>
  <div class="header-component">
    <nh-dialog
      v-model="dialogVisible"
      :title="edit? '编辑':'新建'"
      :track="{
        name_zh: `基础数据/仓库相关/PDA管理/弹窗-${
          edit? '编辑': '新建'
        }`,
        trackName: $route.path
      }"
      custom-class="custom-dialog_50"
      @close="handleClose"
    >
      <el-form
        ref="ruleForm"
        :model="form"
        label-width="100px"
        :rules="rules"
      >
        <el-row :gutter="20">
          <el-col v-if="edit" :span="12">
            <el-form-item label="PDA编码" prop="pdaCode">
              <el-input v-model="form.pdaCode" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备识别码" prop="pdaIdentity">
              <el-input v-model="form.pdaIdentity" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="品牌" prop="pdaBrand">
              <el-select
                v-model="form.pdaBrand"
                placeholder="请选择"
                @change="pdaBrandChange"
              >
                <el-option
                  v-for="item in PDA_BRAND"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="型号" prop="pdaBrandType">
              <el-select
                v-model="form.pdaBrandType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in PDA_BRAND_LIST"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备属性" prop="ownershipType">
              <el-select
                v-model="form.ownershipType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in SHIP_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="所属方："
              prop="ownershipName"
              :rules="[
                { required: DICTIONARY.LEASE === form.ownershipType, message: '请输入所属方'},
              ]"
            >
              <el-input v-model="form.ownershipName" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备类型" prop="pdaType">
              <el-select v-model="form.pdaType" placeholder="请选择">
                <el-option
                  v-for="item in PDA_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="edit" :span="12">
            <el-form-item label="设备状态" prop="pdaStatus">
              <el-select v-model="form.pdaStatus" placeholder="请选择">
                <el-option
                  v-for="item in DEVICE_STATUS"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <nh-button
            type="primary"
            :loading="loading.headleSubmit"
            @click="headleSubmit"
          >
            确 定
          </nh-button>
          <nh-button @click="close">
            取 消
          </nh-button>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import mixin from '../mixin';
import {
  DICTIONARY, PDA_TYPE, DEVICE_STATUS, SHIP_TYPE,
} from '../fileds';
import { pdaInsert, pdaUpdate } from '../api';

export default {
  name: 'HeaderComponent',
  components: {},
  mixins: [loadingMixin, mixin],
  emits: ['headleSuccess'],
  data() {
    return {
      DICTIONARY,
      PDA_TYPE,
      DEVICE_STATUS,
      SHIP_TYPE,
      edit: false,
      dialogVisible: false,
      loading: {
        headleSubmit: false,
      },
      form: {
        pdaCode: null,
        pdaIdentity: null,
        pdaBrand: null,
        ownershipCode: null,
        pdaBrandType: null,
        ownershipType: null,
        pdaType: null,
        pdaStatus: null,
      },
      rules: {
        pdaCode: [
          { required: true, message: '请输入PDA编码', trigger: 'blur' },
        ],
        pdaIdentity: [
          { required: true, message: '请输入设备识别码', trigger: 'blur' },
        ],
        pdaBrand: [
          { required: true, message: '请输入品牌', trigger: 'blur' },
        ],
        pdaBrandType: [
          { required: true, message: '请输入型号', trigger: 'blur' },
        ],
        ownershipType: [
          { required: true, message: '请选择设备属性', trigger: 'change' },
        ],
        pdaType: [
          { required: true, message: '请选择设备类型', trigger: 'change' },
        ],
        pdaStatus: [
          { required: true, message: '请选择设备状态', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    init(data) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        if (data) {
          this.form = { ...data };
          this.pdaBrandChange();
        } else {
          this.form = {
            pdaCode: null,
            pdaIdentity: null,
            pdaBrand: null,
            ownershipCode: null,
            pdaBrandType: null,
            ownershipType: null,
            pdaType: null,
            pdaStatus: null,
          };
        }
        this.edit = !!data;
      });
    },
    async headleSubmit() {
      await this.$refs.ruleForm.validate();
      const data = {};
      Object.keys(this.form).forEach((item) => {
        if (this.form[item]) {
          data[item] = this.form[item];
        }
      });
      if (!this.edit) {
        await pdaInsert(data);
      } else {
        await pdaUpdate(data);
      }
      this.$message({
        type: 'success',
        message: `${this.edit ? '修改' : '添加'}成功!`,
      });
      this.$emit('headleSuccess');
      this.close();
    },
    close() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.ruleForm.resetFields();
    },
    pdaBrandChange(val) {
      if (val) {
        this.form.pdaBrandType = null;
      }
    },
  },
};
</script>
