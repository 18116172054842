export default {
  computed: {
    PDA_BRAND() {
      return this.$dictionaryFiledMap.PDA_BRAND || [];
    },
    PDA_BRAND_LIST() {
      const { pdaBrand } = this.form;
      if (pdaBrand) {
        return this.$dictionaryFiledMap[pdaBrand] || [];
      }
      return [];
    },
  },
};
