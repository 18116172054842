import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: PDA 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11058
 */

export function getPdaPage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/pda/page', data, {
    params,
  });
}
/**
 * @description: PDA 根据ID获取
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11054
 */
export function getPda(params) {
  const { id } = { ...params };
  return wmsPlusHttp.get(`/warehouse_foundation/pda/get?id=${id}`);
}
/**
 * @description: PDA 根据ID删除
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11056
 */
export function deletePda(params) {
  const { id } = { ...params };
  return wmsPlusHttp.get(`/warehouse_foundation/pda/delete?id=${id}`);
}
/**
 * @description: PDA 修改
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11052
 */

export function pdaUpdate(data) {
  return wmsPlusHttp.post('/warehouse_foundation/pda/update', data);
}
/**
 * @description:PDA 新增
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11050
 */

export function pdaInsert(data) {
  return wmsPlusHttp.post('/warehouse_foundation/pda/insert', data);
}
