import { FormType } from '@/constant/form';

const DICTIONARY = {
  LEASE: 'LEASE',
};
const PDA_TYPE = [{
  label: '常温',
  value: 'NORMAL',
}, {
  label: '冷冻型',
  value: 'FROZEN',
}];
const DEVICE_STATUS = [{
  label: '正常',
  value: 'NORMAL',
}, {
  label: '维修中',
  value: 'MAINTAIN',
},
{
  label: '已报废',
  value: 'SCRAPPED',
}];
const ACTIVE = [{
  label: '在线',
  value: true,
}, {
  label: '已下线',
  value: false,
}];
const SHIP_TYPE = [{
  label: '自有',
  value: 'OWN',
}, {
  label: '租赁',
  value: 'LEASE',
}];

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}

const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: 'PDA编码',
  prop: 'pdaCode',
  minWidth: 80,
}, {
  label: '设备识别码',
  prop: 'pdaIdentity',
  minWidth: 120,
}, {
  label: '设备类型',
  prop: 'pdaType',
  minWidth: 120,
  formatter: (row) => filterLabel(row.pdaType, PDA_TYPE),
}, {
  label: '品牌',
  prop: 'pdaBrand',
  minWidth: 120,
}, {
  label: '型号',
  prop: 'pdaBrandType',
  minWidth: 120,
}, {
  label: '设备状态',
  prop: 'pdaStatus',
  minWidth: 80,
  formatter: (row) => filterLabel(row.pdaStatus, DEVICE_STATUS),
}, {
  label: '使用状态',
  prop: 'online',
  minWidth: 80,
  formatter: (row) => filterLabel(row.online, ACTIVE),
}, {
  label: '当前登陆人',
  prop: 'accountUse',
  minWidth: 110,
}, {
  label: '创建时间',
  prop: 'createTime',
  minWidth: 180,
}, {
  label: '最后登陆人',
  prop: 'lastLoginUserName',
  minWidth: 110,
}, {
  label: '最后登陆时间',
  prop: 'lastLoginTime',
  minWidth: 180,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 80,
}];

export {
  DICTIONARY, PDA_TYPE, DEVICE_STATUS, ACTIVE, SHIP_TYPE, TABLECOLUMN,
};
export const serchFields = [
  {
    label: 'PDA编码',
    prop: 'pdaCode',
    component: FormType.INPUT,
  }, {
    label: '识别码',
    prop: 'pdaIdentity',
    component: FormType.INPUT,
  }, {
    label: '设备类型',
    prop: 'pdaType',
    component: FormType.SELECT,
    options: PDA_TYPE,
  }, {
    label: '设备状态：',
    prop: 'pdaStatus',
    component: FormType.SELECT,
    options: DEVICE_STATUS,
  }, {
    label: '使用状态',
    prop: 'online',
    component: FormType.SELECT,
    options: ACTIVE,
  },
];
